<template>
  <v-col>
    <v-autocomplete
      item-text="label"
      item-value="value"
      :items="items"
      label="Тип проекта"
      v-model="itemValue"
      menu-props="closeOnContentClick"
      deletable-chips
      multiple
      small-chips
      hide-no-data
      hide-selected
      @change="$emit('applyFilter', itemValue)"
    ></v-autocomplete>
  </v-col>
</template>

<script>
export default {
  name: "InitiatorTypeSearch",
  props: {
    value: {
      type: Array,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
    };
  },
  computed: {
    items: function () {
      return [
        {
          label: "Системный",
          value: "system",
        },
        {
          label: "Мониторинг",
          value: "pulse",
        },
      ];
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
};
</script>
