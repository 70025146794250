<template>
  <v-col>
    <v-select
      item-text="label"
      item-value="value"
      :items="attributes"
      v-model="itemValue"
      label="Статус"
      :change="$emit('applyFilter', itemValue)"
    ></v-select>
  </v-col>
</template>

<script>
export default {
  name: "StatusProjectSearch",
  props: {
    value: {
      type: String,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
    };
  },
  computed: {
    attributes: function () {
      return [
        {
          label: "Все",
          value: null,
        },
        {
          label: "Ошибка",
          value: "error",
        },
        {
          label: "Предупреждение",
          value: "warning",
        },
        {
          label: "Успех",
          value: "ok",
        },
      ];
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
};
</script>
