<template>
  <div class="about">
    <v-row>
      <v-col>
        <h1>{{ $t("projectStatistics.title") }}</h1>
      </v-col>
    </v-row>
    <v-row class="align-end">
      <component
        :is="item"
        v-for="(item, i) of filterList"
        :key="i"
        v-model="filters[filterAlias[item]]"
        @applyFilter="applyFilter($event, item)"
      ></component>
    </v-row>
    <v-btn x-small outlined color="red" dark @click="resetFilters">
      {{ $t("resetFilters") }}
    </v-btn>
    <div class="d-flex justify-lg-space-between align-center">
      <div>
        <span v-if="totalCount > 0" class="body-2">
          {{
            $t("paginationSummary", {
              from: offset + 1,
              to: maxItem,
              totalCount: totalCount,
            })
          }}
        </span>
      </div>
      <div>
        <div class="d-flex align-center">
          <div v-if="pageCount > 1" class="text-center">
            <v-pagination
              v-model="pagination.page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </div>
          <v-select
            v-if="totalCount"
            item-text="label"
            item-value="value"
            :items="attributes"
            :label="$t('orderBy')"
            v-model="sort.sortBy"
            style="max-width: 180px"
          ></v-select>
          <span v-if="sort.sortBy && totalCount > 0" class="ml-2">
            <v-icon
              v-if="sort.desc"
              @click="
                sort.desc = false;
                pagination.page = 1;
              "
            >
              mdi-sort-descending
            </v-icon>
            <v-icon
              v-if="!sort.desc"
              @click="
                sort.desc = true;
                pagination.page = 1;
              "
            >
              mdi-sort-ascending
            </v-icon>
          </span>
        </div>
      </div>
    </div>
    <v-simple-table v-if="totalCount">
      <template v-slot:default>
        <thead>
          <tr>
            <th>Статус</th>
            <th>Площадка</th>
            <th>Проект</th>
            <th>Тип проекта</th>
            <th>Владелец</th>
            <th>Всего айтемов</th>
            <th>Задачи парсинга</th>
            <th>Задачи атомарки</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) of tableItems" :key="i">
            <td>
              <v-icon v-if="item.status === 'error'" color="red lighten-3">
                mdi-alert-circle
              </v-icon>
              <v-icon v-if="item.status === 'ok'" color="green lighten-3">
                mdi-check-circle
              </v-icon>
              <v-icon v-if="item.status === 'warning'" color="yellow lighten-3">
                mdi-alert-circle
              </v-icon>
            </td>
            <td>
              <v-icon
                >mdi-{{
                  item.target === "vkontakte" ? "alpha-b-box" : item.target
                }}</v-icon
              >
            </td>
            <td>
              <a
                v-if="item.url"
                target="_blank"
                :href="item.url"
                class="text-decoration-none font-weight-bold"
              >
                {{ item.title }}
              </a>
            </td>
            <td>
              <span class="font-weight-bold">{{
                initiatorType[item.initiatorType]
              }}</span>
            </td>
            <td>
              <span class="font-weight-bold">{{ item.owner }}</span>
            </td>
            <td>
              <span class="font-weight-bold black--text">{{
                item.projectItems.total
              }}</span
              >/
              <span class="font-weight-bold green--text">{{
                item.projectItems.successful
              }}</span
              >/
              <span class="font-weight-bold red--text">{{
                item.projectItems.failed
              }}</span>
            </td>
            <td>
              <span class="font-weight-bold black--text">{{
                item.parsingTasks.total
              }}</span
              >/
              <span class="font-weight-bold green--text">{{
                item.parsingTasks.successful
              }}</span
              >/
              <span class="font-weight-bold red--text">{{
                item.parsingTasks.failed
              }}</span>
            </td>
            <td>
              <span class="font-weight-bold black--text">{{
                item.atomicTasks.total
              }}</span
              >/
              <span class="font-weight-bold green--text">{{
                item.atomicTasks.successful
              }}</span
              >/
              <span class="font-weight-bold red--text">{{
                item.atomicTasks.failed
              }}</span
              >/
              <span class="font-weight-bold blue--text text--darken-4">{{
                item.atomicTasks.noAccounts
              }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <div>
      <v-overlay
        :absolute="true"
        :value="isLoading"
        justify-center
        align-center
        opacity="0.15"
      >
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import { FETCH_PROJECT_STATUS } from "@/store/modules/project-statistics/actions";
import { FETCH_SERVICES } from "@/store/actions/service";
import { RESET_FILTERS } from "@/store/modules/project-statistics/mutations";
import { emptyFilters } from "@/store/modules/project-statistics/state";
import StatusProjectSearch from "@/views/project-statistics/search-parts/StatusProjectSearch";
import TargetProjectSearch from "@/views/project-statistics/search-parts/TargetProjectSearch";
import InitiatorTypeSearch from "@/views/project-statistics/search-parts/InitiatorTypeSearch";
import PeriodProjectSearch from "@/views/project-statistics/search-parts/PeriodProjectSearch";
import TitleProjectSearch from "@/views/project-statistics/search-parts/TitleProjectSearch";

export default {
  name: "ProjectStatistics",
  components: {
    target: TargetProjectSearch,
    initiatorType: InitiatorTypeSearch,
    titles: TitleProjectSearch,
    status: StatusProjectSearch,
    dateFrom: PeriodProjectSearch,
  },
  data: function () {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState("projectStatistics", ["pagination", "sort", "filters"]),
    ...mapGetters("projectStatistics", [
      "offset",
      "pageCount",
      "totalCount",
      "filteredAndSortedList",
    ]),
    tableItems: function () {
      return this.filteredAndSortedList;
    },
    maxItem: function () {
      return Math.min(
        this.totalCount,
        this.offset + this.pagination.rowsPerPage
      );
    },
    initiatorType: function () {
      return {
        system: "Системный",
        pulse: "Мониторинг",
      };
    },
    attributes: function () {
      return [
        {
          label: "Статус",
          value: "status",
        },
      ];
    },
    filterList: function () {
      return ["target", "initiatorType", "titles", "status", "dateFrom"];
    },
    filterAlias: function () {
      return {
        target: "target",
        initiatorType: "initiatorType",
        titles: "title",
        status: "status",
        dateFrom: "dateFrom",
      };
    },
  },
  methods: {
    loadServices: function () {
      this.$store
        .dispatch("service/" + FETCH_SERVICES, null, { root: true })
        .catch(() => {});
    },
    fetchItems: function () {
      this.isLoading = true;
      this.$store
        .dispatch(`projectStatistics/${FETCH_PROJECT_STATUS}`, null, {
          root: true,
        })
        .then(() => {
          this.isLoading = false;
        });
    },
    applyFilter: function (value, type) {
      this.pagination.page = 1;
      this.filters[this.filterAlias[type]] = value;
      if (type === "dateFrom") {
        this.fetchItems();
      }
    },
    resetFilters: function () {
      this.$store.commit(
        "projectStatistics/" + RESET_FILTERS,
        _.merge({}, emptyFilters)
      );
    },
  },
  mounted() {
    this.loadServices();
    this.fetchItems();
  },
};
</script>
