<template>
  <v-col>
    <v-select
      item-text="label"
      item-value="value"
      :items="attributes"
      v-model="itemValue"
      label="Период выборки"
      :change="$emit('applyFilter', itemValue)"
    ></v-select>
  </v-col>
</template>

<script>
export default {
  name: "PeriodProjectSearch",
  props: {
    value: {
      type: Number,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
    };
  },
  computed: {
    attributes: function () {
      return [
        { label: "Последние 6 часов", value: 6 },
        { label: "Последние 12 часов", value: 12 },
        { label: "Последние 24 часа", value: 24 },
        { label: "Последние 48 часов", value: 48 },
      ];
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
};
</script>
