<template>
  <v-col>
    <v-autocomplete
      :items="availableTargets"
      label="Площадка"
      v-model="itemValue"
      menu-props="closeOnContentClick"
      deletable-chips
      multiple
      small-chips
      hide-no-data
      hide-selected
      @change="$emit('applyFilter', itemValue)"
    ></v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TargetProjectSearch",
  props: {
    value: {
      type: Array,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
    };
  },
  computed: {
    ...mapGetters("service", ["scopes"]),
    availableTargets: function () {
      return this.scopes;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
};
</script>
