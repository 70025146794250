<template>
  <v-col>
    <v-autocomplete
      :items="availableTitles"
      label="Название"
      v-model="itemValue"
      menu-props="closeOnContentClick"
      deletable-chips
      multiple
      small-chips
      hide-no-data
      hide-selected
      @change="$emit('applyFilter', itemValue)"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          {{ data.item }}
        </template>
      </template>
    </v-autocomplete>
  </v-col>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "TitleProjectSearch",
  props: {
    value: {
      type: Array,
    },
  },
  data: function () {
    return {
      itemValue: this.value,
    };
  },
  computed: {
    ...mapGetters("projectStatistics", ["title"]),
    availableTitles: function () {
      return this.title;
    },
  },
  watch: {
    value: {
      handler: function () {
        this.itemValue = this.value;
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
